import React, { useState,useEffect } from 'react';
import './App.css';
import WalletConnection from './components/WalletConnection';
import PaymentButton from './components/PaymentButton';
import Header from './components/Header';
import locales from './locales';
import Ranking from './components/Ranking';
import { BrowserRouter as Router } from 'react-router-dom'; 

function App() {
    const [walletAddress, setWalletAddress] = useState(null);
    const [ranking, setRanking] = useState([]);
    const [error, setError] = useState(null);
    const [language, setLanguage] = useState('en'); // 默认语言为英文
    const t = locales[language];

 useEffect(() => {
        const fetchRanking = async () => {
            try {
                const response = await fetch('https://back.zeronedex.com/api/ranking');
                if (!response.ok) {
                    throw new Error('Network response was error');
                }
                const data = await response.json();
                setRanking(data.data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchRanking();
    }, []);

    return (
        <Router>
            <div
                className="min-h-screen flex flex-col items-center justify-center space-y-10 px-4 lg:px-24 py-12"
                style={{
                    backgroundImage: `url('/background.png')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
            >
                <Header setLanguage={setLanguage} /> {/* 传递setLanguage函数 */}
                <h1 className="text-3xl lg:text-4xl font-bold text-center">{t.title}</h1>
                <WalletConnection t={t} />
                <PaymentButton t={t} />
                {/* <Ranking t={t} /> */}
            </div>
        </Router>
    );
}

export default App;
