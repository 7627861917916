const locales = {
    en: {
        title: "Node Recruitment and FoMo Plan Countdown...",
        connectWallet: "Connect Wallet",
        payButton: "Purchase Node Pay 5SOL",
        Connected: "connected:",
        connected: "Ranking:",
        congratulations: "Congratulations! You are now a DTC node",
        changeWallet: "Change Wallet",
        disconnect: "Disconnect",
        referralPlaceholder: "Referral Address (optional)",
        rank: "Rank",
        referralAddressError: "Address error",
        transactionSuccess: "Transaction successful! Signature: ",
        paymentProcessingError: "Payment processing failed: ",
        fetchingUserInfoError: "Error fetching user info:",
        processingPaymentError: "An error occurred while processing payment.",
        inviteChain:"Invite Chain",
        copyButton: "Copy",
        copySuccess: "Link copied to clipboard!",
    },
    zh: {
        title: "节点招募和 FoMo 计划倒计时...",
        connectWallet: "连接钱包",
        payButton: "购买节点 支付5个sol",
        Connected: "已连接",
        connected: "您的排名:",
        congratulations: "恭喜！您已成为DTC节点",
        changeWallet: "更换钱包",
        disconnect: "断开连接",
        referralPlaceholder: "推荐地址（可选）",
        rank: "排名",
        referralAddressError: "地址错误",
        transactionSuccess: "交易成功！签名：",
        paymentProcessingError: "支付处理失败：",
        fetchingUserInfoError: "获取用户信息出错：",
        processingPaymentError: "支付处理时发生错误。",
        inviteChain:"邀请链接",
        copyButton: "复制",
        copySuccess: "链接已复制到剪贴板！",
    },
    ja: {
        title: "ノードの募集と FoMo 計画のカウントダウン...",
        connectWallet: "ウォレットを接続",
        payButton: "ノード購入、5 SOL 支払う",  
        connected: "あなたのランキング:",
        congratulations: "おめでとうございます！DTCノードになりました",  
        changeWallet: "ウォレットを変更",
        disconnect: "切断",
        invalidReferralAddress: "無効な推薦アドレス",
        selfReferralError: "自分のウォレットを使用できません",
        referralPlaceholder: "推薦アドレス（任意）",
        rank: "ランキング",
        ReferralAddress: "推薦アドレス",
        InvitedAddresses: "招待されたアドレス",
        countdownTitle: "ノード募集とFoMo計画のカウントダウン...",
        inviteChain:"招待チェーンチェーン",
        copyButton: "コピー",
        copySuccess: "リンクがクリップボードにコピーされました！",
    },
    ko: {
        title: "노드 모집 및 FoMo 계획 카운트다운...",
        connectWallet: "지갑 연결",
        payButton: "노드 구매, 5 SOL 결제",  
        connected: "당신의 순위:",
        congratulations: "축하합니다! 이제 DTC 노드가 되었습니다",  
        changeWallet: "지갑 변경",
        disconnect: "연결 해제",
        invalidReferralAddress: "잘못된 추천 주소",
        selfReferralError: "자신의 지갑을 사용할 수 없습니다",
        referralPlaceholder: "추천 주소(선택 사항)",
        rank: "순위",
        ReferralAddress: "추천 주소",
        InvitedAddresses: "초대된 주소",
        countdownTitle: "노드 모집 및 FoMo 계획 카운트다운...",
        inviteChain:"초대 체인 체인",
        copyButton: "복사",
        copySuccess: "링크가 클립보드에 복사되었습니다!",
    },
    vi: {
        title: "Tuyển dụng nút và đếm ngược kế hoạch FoMo...",
        connectWallet: "Kết nối Ví",
        payButton: "Mua Nút, Thanh toán 5 SOL",  
        connected: "thứ hạng của bạn:",
        congratulations: "Xin chúc mừng! Bạn đã trở thành nút DTC",  
        changeWallet: "Thay đổi Ví",
        disconnect: "Ngắt kết nối",
        invalidReferralAddress: "Địa chỉ giới thiệu không hợp lệ",
        selfReferralError: "Không thể sử dụng ví của bạn",
        referralPlaceholder: "Địa chỉ giới thiệu (Tùy chọn)",
        rank: "Xếp hạng",
        ReferralAddress: "Địa chỉ giới thiệu",
        InvitedAddresses: "Địa chỉ được mời",
        countdownTitle: "Đếm ngược Tuyển dụng Nút và Kế hoạch FoMo...",
        inviteChain:"Chuỗi dây mời",
        copyButton: "Sao chép",
        copySuccess: "Liên kết đã được sao chép vào clipboard!",
    },
};

export default locales;
