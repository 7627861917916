import React, { useState } from 'react';
import locales from '../locales';

function Header({ setLanguage }) {
    return (
        <header className="w-full flex justify-between items-center p-4 lg:px-8 absolute top-0 left-0 right-0">
            <div className="flex items-center ml-4 mt-2">
                <img src="/logo.png" alt="Logo" className="h-6" />
            </div>
            <div className="flex items-center space-x-4 mr-4 mt-2">
                <LanguageSwitcher setLanguage={setLanguage} />
            </div>
        </header>
    );
}

function LanguageSwitcher({ setLanguage }) {
    const [isOpen, setIsOpen] = useState(false);
    const languages = [
        { code: 'en', label: 'EN' },
        { code: 'zh', label: '中文' },
        { code: 'ja', label: '日本語' },
        { code: 'ko', label: '한국어' },
        { code: 'vi', label: 'Tiếng Việt' },
    ];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const changeLanguage = (langCode) => {
        setLanguage(langCode);
        setIsOpen(false);
    };

    return (
        <div className="relative">
            <button
                onClick={toggleDropdown}
                className="flex items-center focus:outline-none bg-transparent border-none p-0 m-0"
                style={{ background: 'none' }}
            >
                <img
                    src="/globe.png"
                    alt="Language Switcher"
                    className="h-5 w-5"
                    style={{ filter: 'invert(62%) sepia(89%) saturate(1000%) hue-rotate(5deg) brightness(105%) contrast(104%)' }}
                />
            </button>

            {isOpen && (
                <ul className="absolute right-0 mt-2 w-32 bg-white border border-gray-200 rounded-lg shadow-lg">
                    {languages.map((lang) => (
                        <li
                            key={lang.code}
                            className="px-4 py-2 text-black hover:bg-[#9945FF] hover:text-white cursor-pointer"
                            onClick={() => changeLanguage(lang.code)}
                        >
                            {lang.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Header;
