import React from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';

function WalletConnection({ t }) {
    const { connected, publicKey } = useWallet();

    // Function to shorten the wallet address
    const shortenAddress = (address) => {
        if (!address) return '';
        const firstPart = address.slice(0, 8);
        const lastPart = address.slice(-4);
        return `${firstPart}.....${lastPart}`;
    };

    return (
        <div className="flex flex-col items-center">
            <WalletMultiButton />
            {connected && publicKey ? (
                <p className="text-white mt-4">{t.Connected} {shortenAddress(publicKey.toString())}</p>
            ) : (
                <p className="text-white mt-4">{t.connectWallet}</p>
            )}
        </div>
    );
}

export default WalletConnection;



